//  Newsletter subscription
.block.newsletter {
  margin-bottom: $indent__xl;

  .form.subscribe {
    width: 100%;
  }

  .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top;
  }

  .field {
    margin: 0 0 $indent__s;
  }

/*
  .field {
    margin: 0;

    .control {
      @include lib-icon-font(
          $icon-envelope,
        $_icon-font-size        : 35px,
        $_icon-font-line-height : 33px,
        $_icon-font-color       : $form-element-input-placeholder__color
      );
      display: block;

      &:before {
        position: absolute;
      }
    }

  }

  input {
    padding-left: 35px;
  }
*/
  .title {
    display: none;
  }

  .label {
    @extend .abs-visually-hidden;
  }

  .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%;
  }

  .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .block.newsletter {
    .field {
      margin-right: 5px;

      .control {
        width: 100%;
      }
    }

    .action.subscribe {
      border-radius: $button__border-radius;
    }
  }
}
