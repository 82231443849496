/*
 *  Fallback for older browsers
 */

.ie11 {
  .column .toolbar {
    justify-content: flex-start;
  }

  select::-ms-expand {
    display: none;
  }

  header.page-header .header .minicart-wrapper {
    justify-content: flex-start;
  }
}

// Home tiles
.no-cssgrid {
  .category-list {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: 25%;
      flex: none;
    }
  }
}

.no-cssgrid.csscalc {
  .category-list__item {
    width: calc(25% - 16px);
    margin: 8px;
  }
}

// Product listing
.no-cssgrid {
  .products.products-grid {
    .product-items {
      display: flex;
      flex-wrap: wrap;
    }

    .product-item {
      width: 33.3%;
    }
  }
}

.no-cssgrid.csscalc {
  .products.products-grid {
    .product-item {
      width: calc(33.3% - 16px);
      margin: 8px;
    }
  }
}