.box-tocart .fieldset > .field > .label {
  display: none;
}

.field.qty {
  display: inline-block;
  margin: 0 24px 0 0;

  .control {
    display: flex;

    .quantity-decrease, .quantity-increase {
      display: inline-block;
      box-sizing: border-box;

      padding-top: 2px;
      width: 42px;
      height: 54px;
      text-align: center;
      cursor: pointer;
      font-size: 22px;
      line-height: 54px;

      background-color: transparent;
      transition: background 0.2s ease-out;

      &:hover {
        background-color: $gray2;
      }
    }

    input.qty {
      width: 54px;
      height: 54px;
      padding: 0 3px;
      background: $gray1;
      border: none;
    }
  }
}
