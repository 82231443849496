h1 {
  margin-bottom: $indent__m;
}

button, .action {
  text-transform: uppercase;
}

.fieldset > .field.configurable > .label {
  font-family: $heading__font-family__base;
  font-weight: 500;
  color: $primary;
  text-transform: uppercase;
}

.product.data.items > .item.content {
  box-shadow: inset 0 1px 0 0 #EFE1BA;
}

.product.name a {
  font-family: $heading__font-family__base;
  font-weight: $heading__font-weight__light;
}

@include min-screen($screen__m) {
  .sidebar-main .block-title, .column .toolbar {
    height: 64px;
    padding: 0 $indent__xs 0 0;
    border-bottom: 1px solid $accent;
  }
}

.sidebar-main .block-title {
  display: flex;
  align-items: flex-end;

  padding-bottom: 12px;
  box-sizing: border-box;

  strong {
    font-family: $heading__font-family__base;
    font-size: 1.31em;
  }
}

// Target all toolbars, then undo styles on all but first
.column .toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: $indent__m;

  .page-title-wrapper {
    margin-right: auto;
    max-width: 50%;

    h1 {
      line-height: 1;
      margin-bottom: 0;
    }
  }

  .sorter {
    padding: 0;
  }

  .toolbar-amount {
    padding: 0 2em 0 0;
  }

  .pages {
    float: none;
    margin-right: auto;
  }

  @include max-screen($screen__m) {
    flex-wrap: wrap;

    .page-title-wrapper {
      max-width: 100%;
      width: 100%;
      text-align: center;
      margin-bottom: $indent__s;
    }

    .toolbar-amount {
      display: none;
    }

    .sorter {
      margin: auto;
    }
  }
}

@include max-screen($screen__m) {
  .sidebar .block.filter {
    margin-bottom: $indent__base;
  }
}

.column > .toolbar ~ .toolbar {
  border: none;
  margin-top: $indent__m;

  .page-title-wrapper {
    display: none;
  }
}

.product.data.items > .item.title {
  box-sizing: content-box;
}

.product.info.detailed {
  margin-top: $indent__m;
}

.page.messages {
  margin-top: $indent__s;
}

// Mobile Tabs

@include max-screen($screen__m) {
  .product.data.items > .item.content {
    background: #FFF;
    border: none;
    box-shadow: none;
  }

  .product.data.items > .item.title > .switch {
    height: 32px;
    line-height: 32px;
    font-size: 18px;
  }

  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: $accent;
    color: #FFF;
  }
}

// Swiper

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  background: transparent;
  transition: background-color .3s ease-out, width .3s ease-out, opacity .3s ease-out;

  text-align: center;

  width: 40px;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  margin-top: -30px;

  &:after {
    font-size: 24px;
  }

  &:hover {
    background-color: $gray1;
  }
}

.swiper-button-next {
  right: 10px;
  left: auto;
}

.swiper-button-prev {
  left: 10px;
  right: auto;
}

@media (min-width: 1370px) {
  .swiper-button-prev,
  .swiper-button-next {
    width: 60px;
    height: 80px;
    font-size: 40px;
    line-height: 80px;
    margin-top: -40px;

    &:after {
      font-size: 44px;
    }
  }
}

.swiper-pagination-bullet {
  height: 12px;
  width: auto;
  margin: 0 8px;
  padding: 5px 0;
  display: inline-block;
  border-radius: 0;
  background: transparent;

  &:before {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    background-color: $primary;
    transition: background-color .3s ease-out;
  }
}

.swiper-pagination-bullet-active {
  background: transparent;

  &:before {
    background-color: $accent;
  }
}

// Cart

.cart-summary {
  .cart-totals .mark strong {
    font-weight: 700;
  }

  .discount {
    .title, .coupon .label {
      display: none !important;
    }

    .actions-toolbar .action {
      width: 100%;
    }
  }

  .block.shipping {
    margin-top: $indent__m;

    .title {
      border-top: none;
      cursor: pointer;
      margin: $indent__xs 0 0 0;
    }

    .title strong {
      font-family: $font-family__base;
      font-size: 1em;
      font-weight: normal;
      text-align: center;
    }
  }
}

form.form-login .actions-toolbar, form.password .actions-toolbar {
  margin-left: 0 !important;
}

.message.global.cookie {
  z-index: 2000;
  bottom: auto;
  top: 0;
  padding: 30px 0 50px 0;

  line-height: 1.6;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: ceil(($font-size__base * 1.5));
  }

  > div {
    padding: 0 22px;
    max-width: 800px;
  }

  a {
    font-weight: bold;
  }

  a.decline {
    text-transform: none;
  }
}