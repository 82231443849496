.banner-slider {
  width: 100%;

  .owl-carousel {
    width: 100%;
  }

  .owl-stage-outer, .owl-stage, .owl-item {
    height: 100%;
  }
}

.banner-slider.home-main {
  max-width: 1440px;
  margin: -2px auto 25px auto;
  background: $gray1;

  .owl-carousel {
    height: 600px;
  }

  .owl-stage-outer {
    border-bottom: 10px solid $accent;
  }

  @include max-screen($screen__l) {
    .owl-carousel {
      height: 480px;
    }
  }

  @include max-screen($screen__m) {
    margin-top: -$indent__s;
    margin-bottom: $indent__s;

    .owl-carousel {
      height: 400px;
    }
  }
}

.banner-slider {
  &__item, &__image {
    width: 100%;
    height: 100%;
  }

  &__container {
    @extend .page-container;
  }

  &__cover {
    height: 100%;
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    display: flex;
    align-items: center;
  }

  &__content {
    color: #FFF;

    p {
      text-shadow: 0 2px 3px rgba(0,0,0,0.5);
    }

    h1, h2 {
      color: #FFF;
      text-shadow: 0 3px 8px rgba(0,0,0,0.5);
    }

    .action.large {
      min-width: 200px;
      text-align: center;
    }

    @include min-screen($screen__l) {
      width: 42%;
      margin-left: 6%;
    }

    @include max-screen($screen__l) {
      width: 70%;
      margin: auto auto 40px auto;
    }

    @include max-screen($screen__m) {
      margin: auto auto 25px auto;
      text-align: center;

      p {
        display: none;
      }
    }
  }

  &__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// Owl Theme

.owl-theme {
  .owl-nav button {
    &.owl-prev, &.owl-next {
      position: absolute;
      top: 50%;
      width: 60px;
      height: 80px;
      margin-top: -40px;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;

      background: #FFF;

      &:after {
        color: $accent;
      }

      @include max-screen($screen__m) {
        width: 40px;
        height: 60px;
        margin-top: -30px;
      }
    }

    &.owl-prev span, &.owl-next span {
      display: none;
    }

    &.owl-prev:hover, &.owl-next:hover {
      background: $accent;
    }

    &.owl-prev:hover:after, &.owl-next:hover:after {
      color: #FFFFFF;
    }

    &.owl-prev:after {
      font-family: swiper-icons;
      font-size: 36px;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;

      @include max-screen($screen__m) {
        font-size: 20px;
      }
    }

    &.owl-next {
      &:after {
        font-family: swiper-icons;
        font-size: 36px;
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        content: "next";

        @include max-screen($screen__m) {
          font-size: 20px;
        }
      }

      right: 10px;
      left: auto;
    }

    &.owl-prev {
      left: 10px;
      right: auto;

      &:after {
        content: "prev";
      }
    }
  }

  .owl-dots {
    margin: 10px 0 0 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;
      height: 12px;
      width: auto;
      margin: 0 8px;
      padding: 5px 0;
      display: inline-block;
      border-radius: 0;
      background: transparent;

      span {
        display: block;
        content: '';
        width: 20px;
        height: 2px;
        background-color: $primary;
        -webkit-transition: background-color .3s ease-out;
        transition: background-color .3s ease-out;
      }

      &.active span, &:hover span {
        background: $accent;
      }
    }
  }
}
