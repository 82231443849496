//
//  Shopping cart
//  ---------------------------------------------

.cart {
  .product-image-container {
    padding: 8px;
  }
}

//  Summary block
.cart-summary {
  @extend .abs-add-box-sizing;
  @extend .abs-adjustment-incl-excl-tax;

  .totals-wrapper, .block.shipping {
    @include lib-css(background, $gray1);
    padding: 15px 22px $indent__m;
  }

  h3 {
    width: 100%;
    padding-bottom: $indent__s;
    @include accent-bottom-border;
    margin: $indent__s 0;
  }

  > .title {
    display: block;
    @include lib-heading(h3);
  }

  .block {
    // @extend .abs-discount-block;
    margin-bottom: 0;

    .item-options {
      margin-left: 0;
    }

    .fieldset {
      margin: 15px 0 $indent__m 0;

      .field {
        margin: 0 0 $indent__s;

        &.note {
          font-size: $font-size__s;
        }
      }

      .methods {
        .field {
          > .label {
            display: inline;
          }
        }
      }
    }

    .fieldset.estimate {
      > .legend,
      > .legend + br {
        @extend .abs-no-display;
      }
    }
  }
  .actions-toolbar {
    > .primary {
      button {
        @extend .abs-revert-secondary-color;
      }
    }
  }
}

//  Totals block
.cart-totals {
  @extend .abs-sidebar-totals;
  padding-top: $indent__s;

  .table-wrapper {
    margin-bottom: 0;
    overflow: inherit;
  }
}

//  Products table
.cart.table-wrapper {
  .items {
    thead + .item {
      // border-top: $border-width__base solid $border-color__base;
    }

    > .item {
      border-bottom: $border-width__base solid $border-color__base;
      position: relative;
    }
  }

  .col {
    padding-top: $indent__base;

    &.qty {
      .input-text {
        @extend .abs-input-qty;
        margin: -12px 0 0 0;
      }

      .label {
        @extend .abs-visually-hidden;
      }
    }
  }

  .field.qty {
    margin: 0 auto;
  }

  .item {
    &-actions td {
      padding-bottom: $indent__s;
      text-align: center;
      white-space: normal;
    }

    .col {
      &.item {
        display: block;
        min-height: 75px;
        padding: $indent__m 0 $indent__s 75px;
        position: relative;
      }
    }
  }

  .actions-toolbar {
    @extend .abs-add-clearfix;

    > .action {
      @extend button;
      @include lib-link-as-button();
      margin-bottom: $indent__s;
      margin-right: $indent__s;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .action {
    &.help.map {
      @extend .abs-action-button-as-link;
      font-weight: $font-weight__regular;
    }
  }

  .product {
    &-item-photo {
      display: block;
      left: 0;
      max-width: 60px;
      padding: 0;
      position: absolute;
      top: 15px;
      width: 100%;
    }

    &-item-details {
      white-space: normal;
    }

    &-item-name {
      display: inline-block;
      font-weight: $font-weight__regular;
      margin-top: -6px;
    }
  }

  .gift-registry-name-label {
    &:after {
      content: ':';
    }
  }

  //  Product options
  .item-options {
    @extend .abs-product-options-list;
    @extend .abs-add-clearfix;
    font-size: $font-size__s;
    margin-bottom: $indent__s;
  }

  .product-item-name + .item-options {
    margin-top: $indent__s;
  }

  .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
  }

  .action.configure {
    display: inline-block;
    margin: 0 0 $indent__base;
  }
}

.cart-container {
  .form-cart, .page-title-wrapper {
    @extend .abs-shopping-cart-items;
  }

  .checkout-methods-items {
    @extend .abs-reset-list;
    margin-top: $indent__base;
    text-align: center;

    .action.primary {
      @extend .abs-button-l;
      width: 100%;
    }

    .item {
      & + .item {
        margin-top: $indent__base;
      }
    }
  }

  .page-title-wrapper {
    @include accent-bottom-border;

    h1 {
      margin-bottom: $indent__s;
    }

    // Manually align with sidebar title bar
    @include min-screen($screen__m) {
      margin-top: 17px;
    }
  }
}

//  Products pager
.cart-products-toolbar {
  .toolbar-amount {
    margin: $indent__m 0 15px;
    padding: 0;
    text-align: center;
  }

  .pages {
    margin: 0 0 $indent__m;
    text-align: center;

    .items {
      > .item {
        border-bottom: 0;
      }
    }
  }
}

.cart-products-toolbar-top {
  border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
  margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
  + .cart-tax-total {
    display: block;
  }
}

.cart.table-wrapper,
.order-items.table-wrapper {
  .col.price,
  .col.qty,
  .col.subtotal,
  .col.msrp {
    text-align: center;
  }

  .col.price,
  .col.subtotal {
    padding-top: $indent__m;
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .cart {
    &.table-wrapper {
      overflow: inherit;

      thead {
        .col {
          &:not(.item) {
            display: none;
          }
        }
      }

      .col {
        &.qty,
        &.price,
        &.subtotal,
        &.msrp {
          box-sizing: border-box;
          display: block;
          float: left;
          text-align: center;
          white-space: nowrap;
          width: 33%;

          &:before {
            content: attr(data-th) ':';
            display: block;
            font-weight: $font-weight__bold;
            padding-bottom: $indent__s;
          }
        }

        &.msrp {
          white-space: normal;
        }
      }

      .item {
        .col.item {
          padding-bottom: 0;
        }
      }
    }
  }

  .cart-container {
    .form-cart, .page-title-wrapper {
      @extend .abs-shopping-cart-items-mobile;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .cart-container {
    @extend .abs-add-clearfix-desktop;
    .form-cart, .page-title-wrapper {
      @extend .abs-shopping-cart-items-desktop;
    }

    .widget {
      float: left;
    }
  }

  .cart-summary {
    float: right;
    position: relative;
    width: 23%;

    .actions-toolbar {
      .column.main & {
        @extend .abs-reset-left-margin-desktop;
        > .secondary {
          float: none;
        }
      }
    }

    .block {
      .fieldset {
        .field {
          @include lib-form-field-type-revert($_type: block);
          margin: 0 0 $indent__s;
        }
      }
    }
  }

  .cart {
    &.table-wrapper {
      .items { // Google Chrome version 44.0.2403.107 m fix
        min-width: 100%;
        width: auto;
      }

      .item {
        .col {
          &.item {
            padding: 18px 8px $indent__s;
          }
        }

        &-actions {
          td {
            text-align: right;
          }
        }
      }

      .product {
        &-item-photo {
          display: table-cell;
          max-width: 100%;
          padding-right: $indent__base;
          position: static;
          vertical-align: top;
          width: 1%;
        }

        &-item-details {
          display: table-cell;
          vertical-align: top;
          white-space: normal;
          width: 99%;
        }
      }

      .item-actions {
        .actions-toolbar {
          @extend .abs-reset-left-margin-desktop;
          text-align: left;
        }
      }
    }

    .action {
      margin-bottom: $indent__s;
    }
  }

  //  Products pager
  .cart-products-toolbar {
    .toolbar-amount {
      line-height: 30px;
      margin: 0;
    }

    .pages {
      float: right;
      margin: 0 0 1px;

      .item {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .cart.table-wrapper {
    .cart-products-toolbar {
      + .cart {
        thead {
          tr {
            th.col {
              padding-bottom: 7px;
              padding-top: 8px;
            }
          }
        }
      }
    }

    .cart {
      + .cart-products-toolbar {
        margin-top: $indent__m;
      }
    }
  }
}
