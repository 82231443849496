select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-size: 30px 60px;
  text-indent: .01em;
  text-overflow: '';
}

select {
  height: 42px;
  line-height: 42px;
  padding: 0 28px 0 20px;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  margin: 0;

  background-color: $gray1;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='9' height='9' viewBox='0 0 9 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Emeigee-drop%3C/title%3E%3Cdesc%3ECreated using Figma%3C/desc%3E%3Cg id='Canvas' transform='translate(393 11)'%3E%3Cg id='icons'%3E%3Cg id='meigee-drop'%3E%3Cuse xlink:href='%23path0_stroke' transform='translate(-392 -8)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cpath id='path0_stroke' d='M 3.5 3.5L 3.14645 3.85355L 3.5 4.20711L 3.85355 3.85355L 3.5 3.5ZM -0.353553 0.353553L 3.14645 3.85355L 3.85355 3.14645L 0.353553 -0.353553L -0.353553 0.353553ZM 3.85355 3.85355L 7.35355 0.353553L 6.64645 -0.353553L 3.14645 3.14645L 3.85355 3.85355Z'/%3E%3C/defs%3E%3C/svg%3E");
  background-size: 8px;
  background-position: calc(100% - 7px) center;
  background-repeat: no-repeat;

  transition: background-color .3s ease-out,color .3s ease-out,border-color .3s ease-out;
}

.toolbar select {
  background-color: $accent;
  color: #FFF;
}


button, .button {
  transition: background 0.25s ease-out;
}

.action.large {
  @extend .abs-button-l;
}

form.form.compact {
  .field {
    margin: 0 0 5px;
  }
}

.product-options-wrapper {
  .fieldset > .field {
    @include lib-css(margin, 0 0 $indent__base);
  }
}

.action.alternate {
  border: none;
  background: $primary;
  color: #FFF;

  &:hover {
    background: darken($primary, 10%);
  }
}