.block.filter .filter-title strong {
  font-weight: normal;
  font-size: 1.35em;
  color: $primary;
}

@include max-screen($screen__m) {
  .block.filter .filter-title {
    margin-bottom: 0;
  }
}

.filter-options-item {
  margin-bottom: 18px;

  .filter-options-content {
    display: none;
    padding: 0 0 12px;

    a, .count {
      color: $text__color;
    }

    .count {
      margin-left: 0.25em;
    }
  }

  .filter-options-title {
    position: relative;
    cursor: pointer;

    font-family: $heading__font-family__base;
    font-size: 1.1em;
    font-weight: normal;

    &__toggle {
      position: absolute;
      top: 4px;
      right: 0;

      @extend .las;

      &:before {
        content: la-content($la-plus);
      }
    }
  }
}

.filter-options-item.active {
  .filter-options-title__toggle:before {
    content: la-content($la-minus);
  }

  .filter-options-content {
    display: block;
  }
}
.filter-current {
  &-subtitle {
    font-family: $heading__font-family__base;
    font-size: 1.1rem;
    font-weight: normal;
    color: $primary;
  }
}
