.category-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  &__item {
    height: 360px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    position: relative;
    flex: 1 0 100%;

    padding: 10px;

    a {
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;

        background: $gray1;
        border: 10px solid $gray1;

        transition: border-color 0.25s;
      }

      &:hover:after {
        border: 10px solid #FFF;
      }
    }
  }

  &__title {
    position: absolute;
    left: 0;
    bottom: 32px;

    font-family: $heading__font-family__base;
    font-size: 1.1em;
    font-weight: 500;
    padding: 25px 40px 0;
    color: $primary;
    text-transform: uppercase;

    &:before {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: 28px + 16px + 8px;

      transition: width 0.25s ease;
      width: 40px;
      border-top: 2px solid $gray2;
    }
  }

  &__image {
    display: block;
    position: absolute;
    top: 20px;
    left: 10px;
    right: 10px;
    bottom: 105px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      overflow: hidden;
    }
  }

  a:hover .category-list__title:before {
    width: 100px;
    border-top-color: $accent;
    transform: translateX(10px);
  }
}

@media (min-width: 360px) {
  .category-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@include min-screen($screen__m) {
  .category-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@include min-screen($screen__l) {
  .category-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@include max-screen($screen__m) {
  .category-list {
    &__image {
      top: 15px;
      bottom: 75px;
    }

    &__item {
      height: 220px;
    }

    &__title {
      padding: 15px 25px 0;
      bottom: 20px;

      &:before {
        bottom: 40px;
      }
    }
  }
}
