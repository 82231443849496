//
//  Product Lists
//  _____________________________________________

.products {
  margin: $indent__l 0;
}

.product {
  &-items {
    @extend .abs-reset-list;
  }

  &-item {
    @extend .abs-add-box-sizing;
    vertical-align: top;

    &-name {
      @extend .abs-product-link;
      display: block;
      margin: $indent__xs 0;
      word-wrap: break-word;
      hyphens: auto;
    }

    &-info {
      max-width: 100%;
    }

    &-actions {
      display: none;

      .actions-secondary {
        & > .action {
          @extend .abs-actions-addto-gridlist;
          &:before {
            margin: 0;
          }

          span {
            @extend .abs-visually-hidden;
          }
        }
      }
    }

    &-description {
      margin: $indent__m 0;
    }

    .product-reviews-summary {
      .rating-summary {
        margin: 0 4px 0 0;
      }

      .reviews-actions {
        font-size: $font-size__s;
        margin-top: 5px;
        text-transform: lowercase;
      }
    }

    .price-box {
      margin: $indent__s 0 $indent__s;

      .price {
        font-weight: $heading__font-weight__light;
        white-space: nowrap;

        color: $accent;
      }

      .price-label {
        font-size: $font-size__s;

        &:after {
          content: ':';
        }
      }
    }

    .special-price,
    .minimal-price {
      .price {
        font-size: 14px;
        font-weight: $font-weight__bold;
      }

      .price-wrapper {
        display: inline-block;
      }

      .price-including-tax + .price-excluding-tax {
        display: block;
      }
    }

    .special-price {
      display: block;
    }

    .old-price {
      .price {
        font-weight: $font-weight__regular;
      }
    }

    .minimal-price {
      .price-container {
        display: block;
      }
    }

    .minimal-price-link {
      margin-top: 5px;

      .price-label {
        color: $link__color;
        font-size: 14px;
      }

      .price {
        font-weight: $font-weight__regular;
      }
    }

    .minimal-price-link,
    .price-excluding-tax,
    .price-including-tax {
      display: block;
      white-space: nowrap;
    }

    .price-from,
    .price-to {
      margin: 0;
    }

    .action.tocompare {
      @include lib-icon-font-symbol($icon-compare-empty);
    }

    .tocart {
      white-space: nowrap;
    }
  }
}

.price-container {
  .price {
    font-size: 14px;
  }

  .price-including-tax + .price-excluding-tax,
  .weee {
    margin-top: $indent__xs;
  }

  .price-including-tax + .price-excluding-tax,
  .weee,
  .price-including-tax + .price-excluding-tax .price,
  .weee .price,
  .weee + .price-excluding-tax:before,
  .weee + .price-excluding-tax .price {
    font-size: 11px;
  }

  .weee {
    &:before {
      content: '('attr(data-label) ': ';
    }

    &:after {
      content: ')';
    }

    + .price-excluding-tax {
      &:before {
        content: attr(data-label) ': ';
      }
    }
  }
}

.products-list {
  .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
  }
}

.products-grid {
  .product-items {
    margin: 0;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 15px;
  }

  .product-item {
    padding: $indent__s;

    .product-image-container {
      width: 100% !important;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
  .products-grid {
    .product-item {
      text-align: center;
    }
  }
}

@include min-screen($screen__s) {

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .products-grid {
    .product-items {
      grid-gap: 16px;
    }
  }

  .product-item {
    &-actions {
      display: block;
    }

    .actions-secondary {
      display: none;
    }

    .action.tocart {
      float: right;
    }
  }
}
@include min-screen($screen__l) {
  .products-grid {
    .product-items {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .products-grid.products-related,
  .products-grid.products-crosssell,
  .products-grid.products-new,
  .products-grid.products-upsell {
    .product-items {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  // Highlight box
  .products-grid .product-item {
    position: relative;

    .product-item-info {
      position: relative;
    }

    .action.tocart {
      opacity: 0;
      transform: scale(.8);
      transition: all .3s ease-out;
    }

    &:before {
      content: '';
      opacity: 0;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      box-shadow: none;
      background-color: #FFF;

      transition: top .3s ease-out,
        left .3s ease-out,
        right .3s ease-out,
        bottom .3s ease-out,
        opacity .3s ease-out,
        box-shadow .3s ease-out;
    }

    &:hover {
      &:before {
        opacity: 1;
        left: -16px;
        right: -16px;
        top: -16px;
        bottom: -20px;

        box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
      }

      z-index: 10;

      .action.tocart {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
