.newsletter-sidebar {
  background: $primary;
  padding: 20px;
  color: #FFF;

  h3 {
    margin-top: 0;
    color: #FFF;
  }

  form .field.newsletter {
    margin: 0.25em 0 0.75em 0;
  }
}