$base: #4D4D4D;
$primary: #143149;
$accent: #D3AE43;

$gray1: #F6F6F6;
$gray2: darken($gray1, 5%);

$color-gray-light0: $gray1;

@mixin accent-bottom-border {
  border-bottom: 1px solid $accent;
  box-shadow: 0 1px 0 0 #EFE1BA;
}

//
//  Typography
//  ---------------------------------------------

$font-family-name__base: 'News Gothic MT';
$font-family__sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family__base: $font-family-name__base, $font-family__sans-serif;

$heading__font-family__base: 'Museo', serif;
$heading__font-weight__base: normal;
$heading__font-weight__light: normal;
$heading__line-height__base: 1.25;

$root__font-size: 100%;
$font-size-unit: rem;
$font-size-unit-ratio: calc($root__font-size * 16 / 100 / 1%);
$font-size-ratio__base: 1;
$font-size__base: $font-size-unit-ratio * $font-size-ratio__base * 1px;
$font-size-unit-convert: false;

//
//  Indents
//  ---------------------------------------------

$line-height__base: 1.428571429;
$line-height__computed: floor($font-size__base * $line-height__base);

$indent__base: $line-height__computed;
$indent__xl: $indent__base * 2;
$indent__l: $indent__base * 1.5;
$indent__m: $indent__base * 1.25;
$indent__s: calc($indent__base / 2);
$indent__xs: calc($indent__base / 4);

//
// Font Size
//  ---------------------------------------------

$heading__margin-bottom__base: $indent__s;
$h1__margin-bottom: $indent__s;
$h1__font-size: ceil(($font-size__base * 2));
$h1__font-size-desktop: ceil(($font-size__base * 2.25));
$h2__font-size: ceil(($font-size__base * 2));

//
//  Links
//  ---------------------------------------------

$link__color                    : $accent;
$link__text-decoration          : none;
$link__visited__color           : $accent;
$link__visited__text-decoration : none;
$link__hover__color             : $primary;
$link__hover__text-decoration   : underline;
$link__active__color            : $primary;
$link__active__text-decoration  : underline;

//
//  Colours
//  ---------------------------------------------

$primary__color: $primary;
$secondary__color: $accent;
$active__color: $accent;

$text__color: $base;

$success__color: rgb(50,171,57);

$heading__color__base: $primary;

//
//  Buttons
//  ---------------------------------------------

$button__font-family: $font-family__base;
$button__font-weight: normal;

$button__padding: 12px 16px;
$button__background: none;
$button__border: 1px solid $accent;
$button__border-radius: 0;
$button-primary__border: none;
$button-primary__hover__border: none;
$button-primary__background: $accent;
$button-primary__hover__background: $primary;

// $button__font-size__l: ceil($font-size__base * 1);
$button__font-size: ceil($font-size__base * 0.9);
$button__font-size__l: ceil($font-size__base * 0.9);
$button__padding__l: 16px 20px;

$pager-action__color: $accent;

//
//  Layout
//  ---------------------------------------------

$layout-column__sidebar-width: 3;

$layout-column-main__sidebar-offset: 3%;
$product-grid-items-per-row-layout-2-left-screen-m: 3;
$product-grid-items-per-row-layout-default: 2; // 3x Products per row

//
//  Tabs
//  ---------------------------------------------

$tab-control__font-weight: normal;
$tab-control__border-width: 1px;
$tab-control__border-color: $accent;
$tab-control__font-size: $font-size__base * 1.2;

$tab-control__height: $indent__base;
$tab-control__padding-top: 15px;
$tab-control__padding-bottom: 14px;
$tab-control__padding-left: 24px;
$tab-control__padding-right: 24px;

$tab-content__margin-top: $tab-control__height + $tab-control__padding-top + $tab-control__padding-bottom;

$tab-control__background-color: #FFF;
$tab-control__color: $text__color;
$tab-control__active__background-color: $gray1;
$tab-content__background-color: #FFF;

//
//  Form variables
//  _____________________________________________

// Field
$form-field__vertical-indent: 10px;
$form-field__vertical-indent__desktop: $form-field__vertical-indent;
$form-field__additional-vertical-indent: 0;

$form-field-type-label-inline__padding-top: 12px;

// Fieldset
// $form-fieldset__margin: 0 0 $indent__m;
// $form-fieldset-legend__margin: 0 0 $indent__s;

// Element
$form-element-input__padding: 0 14px;
$form-element-input__height: 46px;
$form-element-input__border-color: hsla(0, 0%, 92%, 1);
$form-element-input__background: hsla(0, 0%, 97%, 1);
$form-element-input__focus__background: #FFF;

$form-element-input-placeholder__color: $primary__color;

//
//  Navigation
//  _____________________________________________

$navigation-level0-item__font-size: 14px;

$navigation-desktop__font-size : 14px;
$navigation-desktop__font-weight: 900;

$navigation-desktop-level0-item__padding: 13px 0.1em;
$navigation-desktop-level0-item__line-height: 1.2;
$navigation-desktop-level0-item__color: rgba($primary, 0.75);

$navigation__background-active: hsla(0, 0%, 95%, 1);
$navigation-desktop-level0-item__hover__color: $accent;
$navigation-desktop-level0-item__active__color: $accent;
$navigation-desktop-level0-item__hover__background: $navigation__background-active;
$navigation-desktop-level0-item__active__background: $navigation__background-active;
$submenu-desktop__background: $navigation__background-active;

$submenu-desktop__min-width: 270px;
$submenu-desktop__border-width: 0;
$submenu-desktop__padding: 10px 0;
$submenu-desktop-item__padding: 9px 28px;
$submenu-desktop-item__color: #000;
$submenu-desktop__font-weight: normal;

$submenu-desktop-item__hover__background: #FFF;
$submenu-desktop-item__hover__color: $primary;

// Mobile Navigation Specific
$navigation__background: #FFF;

$navigation-level0-item__font-weight: 700;
$navigation-level0-item__color: rgba($primary, 0.75);
$navigation-level0-item__padding: 10px $indent__xl 10px 15px;

$navigation-level0-item__active__background: $navigation__background-active;
$navigation-level0-item__active__border-color: transparent;
$submenu-item__active__border-color: transparent;

//
//  Messages
//  _____________________________________________

$message__margin: 0 0 $indent__m 0;
$message__font-size: 14px;

//
// Other
//  _____________________________________________

$breadcrumbs__container-margin: 0;

$checkout-step-title__border: 1px solid $accent;
$checkout-step-title__font-weight: normal;
