.breadcrumbs {
  .item:not(:last-child)::after {
    content: '/';
    color: $primary;
    margin-top: -2px;
  }

  .item {
    text-transform: uppercase;
    font-family: $heading__font-family__base;
    font-size: 0.9em;
  }

  strong {
    color: $accent;
  }
}
