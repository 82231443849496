
.page-footer {
  background-color: $primary;;
  margin-top: auto;
  font-size: 0.925em;
}

.page-footer {
  .footer.content {
    margin-top: 25px;
    padding-bottom: 25px;
    padding-top: 25px;

    color: #FFF;

    ul {
      @extend .abs-reset-list;
    }

    a:hover {
      color: $accent;
      text-decoration: underline;
    }

    p a {
      color: #FFF;
      font-weight: bold;
    }

    .links {
      > li {
        margin: 0 0 8px;
      }
    }

    .switcher-store {
      margin: 0 0 30px;
    }
  }

  .form.contact {
    .legend, .fieldset {
      margin: 0 0 $indent__s;
    }

    .legend {
      color: $accent;
      font-family: $heading__font-family__base;
      font-size: 1em;
    }

    .actions-toolbar {
      margin-top: $indent__m;
    }
  }

  .action.primary:hover {
    background: darken($primary, 10%);
  }

  .block.newsletter {
    margin: $indent__m 0;

    h3 {
      color: $accent;
      font-size: 1.4em;
      margin: 0 0 $indent__xs;
    }
  }
}

@include min-screen($screen__m) {
  .page-footer .footer.content {
    display: grid;
    grid-template-columns: 1fr 220px 220px 1fr;
    grid-gap: 40px;
    grid-template-areas: "info address1 address2 contact";

    .info { grid-area: info; }
    .address1 { grid-area: address1; }
    .address2 { grid-area: address2; }
    .contact { grid-area: contact; }

    .address1, .address2 {
    }

    strong, b {
      color: $accent;
    }
  }
}

.final-footer {
  background-color: darken($primary, 10%);
  color: #FFF;
  padding: 1em 0 1em 0;
  font-size: 0.9em;

  p {
    margin: 0;
  }

  a:hover {
    color: #FFF;
  }
}

@include min-screen($screen__m) {
  .final-footer .content {
    display: flex;
    align-items: center;

    p {
      flex-basis: 0;
      flex-grow: 1;

      &:last-child {
        text-align: right;
      }
    }
  }
}
