$layout-column-main__sidebar-offset       : 2% !default;
$layout-column__additional-sidebar-offset : $layout-column-main__sidebar-offset !default;

.columns {
  @include lib-layout-columns();

  .column.main {
    @extend .abs-add-box-sizing;
    @include lib-css(padding-bottom, $indent__xl);
    @include lib-vendor-prefix-flex-basis(100%);
    @include lib-vendor-prefix-flex-grow(1);
    @include lib-vendor-prefix-order(1);
    width: 100%;
  }

  .sidebar-main {
    @extend .abs-add-box-sizing;
    @include lib-vendor-prefix-flex-grow(1);
    @include lib-vendor-prefix-flex-basis(100%);
    @include lib-vendor-prefix-order(1);
  }

  .sidebar-additional {
    @extend .abs-add-box-sizing;
    @include lib-vendor-prefix-flex-grow(1);
    @include lib-vendor-prefix-flex-basis(100%);
    @include lib-vendor-prefix-order(2);
    margin-bottom: $indent__l;
    margin-top: $indent__s;
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-main,
  .page-wrapper > .widget,
 /* .page-wrapper > .page-bottom, */
  .page-container,
  .block.category.event,
  .top-container {
    padding-left: $layout__width-xs-indent;
    padding-right: $layout__width-xs-indent;
  }

  .page-main {
    .account &,
    .cms-privacy-policy & {
      padding-top: 41px;
      position: relative;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-wrapper > .widget,
  /* .page-wrapper > .page-bottom, */
  .page-container,
  .block.category.event,
  .top-container,
  .page-main {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: $layout__max-width;
    padding-left: $layout-indent__width;
    padding-right: $layout-indent__width;
    width: auto;
  }

  .page-main {
    width: 100%;

    // These flex properties should be combined to `flex: 1 0 auto`
    // sass-lint:disable no-css-comments
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    @include lib-vendor-prefix-flex-grow(1);
    @include lib-vendor-prefix-flex-shrink(0);
    @include lib-vendor-prefix-flex-basis(auto);
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    // sass-lint:enable  no-css-comments

    .ie9 & {
      width: auto;
    }
  }

  .columns {
    display: block;
  }

  .column.main {
    @extend .abs-add-box-sizing-desktop;
    @include lib-layout-columns__main();
    min-height: 300px;
  }

  .sidebar-main {
    @extend .abs-add-box-sizing-desktop;
    @include lib-layout-columns__left();
    padding-right: $layout-column-main__sidebar-offset;
  }

  .page-layout-2columns-right .sidebar-main {
    padding-left: $layout-column-main__sidebar-offset;
    padding-right: 0;
  }

  .sidebar-additional {
    @extend .abs-add-box-sizing-desktop;
    @include lib-layout-columns__right();
    clear: right;
    padding-left: $layout-column__additional-sidebar-offset;
  }

  .page-layout-2columns-left {
    .sidebar-additional {
      clear: left;
      float: left;
      padding-left: 0;
      padding-right: $layout-column__additional-sidebar-offset;
    }
  }

  .panel.header {
    padding: 10px 20px;
  }
}
