@include  min-screen($screen__m) {
  .block-search {
    width: auto;
    max-width: 700px;
    margin: 0 auto;
    flex-grow: 1;

    .control {
      padding: 0;
    }

    input[type=text] {
      font-size: 18px;
      height: 48px;
      line-height: 48px;
      padding: 0 18px;
    }
  }
}