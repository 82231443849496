.search-autocomplete {
  position: absolute;
  z-index: 300;
  background: #FFF;
  overflow: hidden;

  dl {
    @include lib-list-reset-styles();
    dt {
      &:not(:empty) {
        @include lib-css(background, $autocomplete__background-color);
        @include lib-css(border, $autocomplete__border);
        border-top: 0;
        border-bottom: 0;
      }
      @include lib-css(border-top, $autocomplete-item__border);
      cursor: default;
      margin: 0;
      padding: $indent__xs $indent__xl $indent__xs $indent__s;
      position: relative;
      text-align: left;
      white-space: normal;
      &:first-child {
        border-top: none;
      }
      &:hover,
      &.selected {
        @include lib-css(background, $autocomplete-item__hover__color);
      }
    }
    dd {
      &:not(:empty) {
        @include lib-css(background, $autocomplete__background-color);
        @include lib-css(border, $autocomplete__border);
        border-top: 0;
      }
      @include lib-css(border-top, $autocomplete-item__border);
      cursor: pointer;
      margin: 0;
      padding: $indent__xs $indent__s;
      position: relative;
      text-align: left;
      white-space: normal;
      &:first-child {
        border-top: none;
      }
      &:hover,
      &.selected {
        @include lib-css(background, $autocomplete-item__hover__color);
      }
      .amount {
        @include lib-css(color, $autocomplete-item-amount__color);
        position: absolute;
        right: 7px;
        top: $indent__xs;
      }
      .clear {
        clear:both;
      }
    }
  }

  .product-item {
    margin-left: $indent__s;

    .price-box {
      margin: 0;
    }
  }
}

.smile-elasticsuite-autocomplete-result {
  // Products list
  .autocomplete-list:nth-child(2) dd {
    display: flex;
  }
}

.filter-options input.filter-search {
  margin-bottom: $indent__s;
  height: 36px;

  background: hsla(0, 0%, 95%, 1);
  font-size: 0.9rem;
}

.filter-options .filter-options-content, .filter-current {
  font-size: 0.9rem;
}

@include max-screen($screen__m) {
  .search-autocomplete {
    margin-top: 0;
  }
}
