@import 'global';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@import 'vendor/line-awesome/line-awesome';

// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Reset default styles with magento-reset
@import 'blocks/reset';

// Theme variables
@import 'variables';

// Vendors with integration
@import 'vendor/swiper';
@import 'vendor/owl.carousel';

// Theme blocks
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/pages'; // Theme pager
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/typography';

// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
// @import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';


// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

// EC Snaith
@import "layout/base";
@import "layout/header";
@import "layout/footer";
@import "layout/search";
@import "layout/catalog";

@import "components/overrides";
@import "components/usps";
@import "components/filters";
@import "components/controls";
@import "components/breadcrumbs";
@import "components/tabs";
@import "components/qty";
@import "components/product_slider";
@import "components/banner_slider";
@import "components/testimonial_slider";
@import "components/category_list";
@import "components/hr-title";
@import "components/newsletter";
@import "components/search";

@import "blocks/checkout";

.mg, .mg-2 { margin: 1em; }
.mg-t, .mg-t-2 { margin-top: 1em; }
.mg-b, .mg-b-2 { margin-bottom: 1em; }
.mg-r, .mg-r-2 { margin-right: 1em; }
.mg-l, .mg-l-2 { margin-left: 1em; }

.mg-0 { margin: 0; }
.mg-t-0 { margin-top: 0; }
.mg-b-0 { margin-bottom: 0; }
.mg-r-0 { margin-right: 0; }
.mg-l-0 { margin-left: 0; }

.mg-1 { margin: 0.5em; }
.mg-t-1 { margin-top: 0.5em; }
.mg-b-1 { margin-bottom: 0.5em; }
.mg-r-1 { margin-right: 0.5em; }
.mg-l-1 { margin-left: 0.5em; }

.mg-3 { margin: 1.5em; }
.mg-t-3 { margin-top: 1.5em; }
.mg-b-3 { margin-bottom: 1.5em; }
.mg-r-3 { margin-right: 1.5em; }
.mg-l-3 { margin-left: 1.5em; }

.mg-4 { margin: 2em; }
.mg-t-4 { margin-top: 2em; }
.mg-b-4 { margin-bottom: 2em; }
.mg-r-4 { margin-right: 2em; }
.mg-l-4 { margin-left: 2em; }

.mg-5 { margin: 2.75em; }
.mg-t-5 { margin-top: 2.75em; }
.mg-b-5 { margin-bottom: 2.75em; }
.mg-r-5 { margin-right: 2.75em; }
.mg-l-5 { margin-left: 2.75em; }

.mg-6 { margin: 3.5em; }
.mg-t-6 { margin-top: 3.5em; }
.mg-b-6 { margin-bottom: 3.5em; }
.mg-r-6 { margin-right: 3.5em; }
.mg-l-6 { margin-left: 3.5em; }

// Include fallback last so it can override anything
@import "fallback";
