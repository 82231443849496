.banner-slider.home-testimonials {
  background: $primary;
  margin: 20px 0;

  .owl-stage-outer {
    position: relative;
  }

  .owl-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;

    .owl-dot span {
      background-color: #FFF;
    }

    .owl-dot.active span {
      background-color: $accent;
    }
  }
}

.banner-slider__testimonial {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  min-height: 460px;

  blockquote {
    position: relative;
    align-self: center;
    font-size: 1.5em;
    margin: 0;
    color: #FFF;
    font-style: normal;
    text-align: center;
    padding: 70px 0 0 0;
    margin: 60px 0 80px 0;

    /*
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.2 36.4">
  <path d="M10.7 0h5.7L7 20.7h6.4v15.7H0V20.7L10.7 0zm25.7 0h5.8l-9.6 20.7h6.7v15.7H26V20.7L36.4 0z" fill="#d3ae43"/>
</svg>
     */

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
      height: 36px;

      background-size: 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42.2 36.4'%3E%3Cpath d='M10.7 0h5.7L7 20.7h6.4v15.7H0V20.7L10.7 0zm25.7 0h5.8l-9.6 20.7h6.7v15.7H26V20.7L36.4 0z' fill='%23d3ae43'/%3E%3C/svg%3E%0A");
    }
  }

  cite {
    display: block;
    padding: 1em 0 0 0;
    color: $accent;
  }

  .banner-slider__testimonial-image {
    position: relative;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  @include max-screen($screen__l) {
    padding-left: 2em;
    padding-right: 2em;

    blockquote {
      font-size: 1.2rem;
    }
  }

  @include max-screen($screen__m) {
    min-height: auto;

    blockquote {
      font-size: 1.1rem;
      padding: 50px 0 0 0;
      margin: 35px 0 55px 0;

      &:before {
        width: 32px;
        height: 28px;
      }
    }
  }
}

@include min-screen($screen__l) {
  .banner-slider__testimonial-split {
    grid-template-columns: 2fr 1fr;

    blockquote {
      text-align: left;
    }
  }
}

@include max-screen($screen__l) {
  .banner-slider__testimonial-image {
    display: none;
  }
}