.block-products-list {
  max-width: 100%;
}

.products-grid.product-slider {
  box-sizing: border-box;

  padding: 0 5px 50px;
  width: calc(100% + 10px);
  margin: 0 -5px;

  // Initial state
  transition: opacity .3s ease-out, transform .2s ease-out;
  opacity: 0;
  transform: scale(.9);

  &.swiper-container-initialized {
    opacity: 1;
    transform: scale(1);
  }

  .product-items {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    grid-gap: 0; // Make sure to reset

    .product-item {
      position: relative;
      padding: 0 10px;
      flex: 0 0 auto;
      float: none;
      box-sizing: border-box;
    }

    .product-item.swiper-slide {
      opacity: 0;
      transition: opacity .5s ease-out, transform .3s ease-out;
      &.swiper-slide-visible {
        opacity: 1;
        &.swiper-slide-prev, &.swiper-slide-duplicate-prev {
          opacity: 0;
        }
      }
    }
  }
}

@include min-screen($screen__m) {
  .products-grid.product-slider {
    padding: $indent__m 10px 50px;
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
}

@media (min-width: 1370px) {
  .products-grid.product-slider {
    padding: $indent__m 70px 50px;
    width: calc(100% + 160px);
    margin: 0 -80px;
  }
}

@include max-screen($screen__m) {
  .products-grid.product-slider {
    text-align: center;

    .product-image-container {
      padding: 0 36px;
    }
  }
}
