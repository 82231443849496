
@font-face {
  font-family: 'Museo';
  src: url('../fonts/Museo-500.woff2') format('woff2'),
  url('../fonts/Museo-500.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src: url('../fonts/Museo900-Regular.woff2') format('woff2'),
  url('../fonts/Museo900-Regular.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'News Gothic MT';
  src: url('../fonts/NewsGothicMT-Bold.woff2') format('woff2'),
  url('../fonts/NewsGothicMT-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'News Gothic MT';
  src: url('../fonts/NewsGothicMT.woff2') format('woff2'),
  url('../fonts/NewsGothicMT.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  h1 {
    @include lib-css(font-size, $h1__font-size-desktop);
    @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
  }
}

.items {
  @include lib-list-reset-styles();
}