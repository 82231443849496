.block-product-link,
.block-category-link {
  &.widget {
    display: block;
    margin-bottom: $indent__base;
  }
}

.block-product-link-inline {
  &.widget {
    margin: 0;
  }
}

.block.widget {
  .product-item-info {
    width: auto;
  }

  .pager {
    padding: 0;

    .toolbar-amount {
      float: none;
      @include lib-font-size(12);
    }

    .pages-item-previous {
      padding-left: 0;
    }

    .pages-item-next {
      position: relative;
    }

    .items {
      white-space: nowrap;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

}

@include min-screen($screen__l) {
  .page-layout-1column .block.widget .products-grid .product-items {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@include min-screen($screen__m) {
  .sidebar .block.widget .pager {
    .item:not(.pages-item-next):not(.pages-item-previous) {
      @extend .abs-no-display-desktop;
    }

    .pages-item-next {
      padding: 0;

      .action {
        margin: 0;
      }
    }
  }
}
