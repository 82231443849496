.usps {
  margin-bottom: $indent__s;
  background: hsla(0, 0%, 95%, 1);

  .header.content {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    > .usp {
      flex-basis: 0;
      flex-grow: 2;
    }
  }

  @include max-screen($screen__l) {
    display: none;
  }
}

.usp {
  text-align: center;
  margin: 7px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    font-weight: normal;
    margin: 0 0 0 12px;
  }

  &__icon {
    width: 34px;
    height: 24px;
    overflow: hidden;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include min-screen($screen__m) {
}

@include max-screen($screen__l) {
  .usps {
    flex-wrap: wrap;

    > .usp {
      flex-basis: 50%;
    }
  }
}

@include max-screen($screen__m) {
  .usps > .usp {
    flex-basis: 100%;
  }
}
