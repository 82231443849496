.hr-title {
  position: relative;
  display: block;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: $indent__s;

  h1, h2, h3 {
    display: inline-block;
    background: #FFF;
    padding: 0 0.75em;
    position: relative;
    z-index: 10;
    margin: 0;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: $accent;
    box-shadow: 0 1px 0 0 #EFE1BA;
  }
}