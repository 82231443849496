$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
  text-decoration: line-through;
}

.prices-tier {
  .price-container {
    .price-including-tax {
      + .price-excluding-tax {
        &:before {
          content: '(' attr(data-label) ': ';
        }

        &:last-child:after {
          content: ')';
        }
      }
    }

    .weee[data-label] {
      display: inline;

      .price {
        @include lib-font-size(11);
      }

      &:before {
        content: ' +' attr(data-label) ': ';
      }
    }
  }
}

.actual-price {
  font-weight: $font-weight__bold;
}

.product.name a {
  @extend .abs-product-link;
}

.category-image {
  .image {
    display: block;
    height: auto;
    max-width: 100%;
  }
}

.category-image,
.category-description {
  margin-bottom: $indent__base;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
  display: inline-block;
  max-width: 100%;
}

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
  padding: 20px;

  .product.photo .photo.image {
    @extend .abs-adaptive-images-centered;
  }

  .placeholder .photo.container {
    max-width: 100%;
  }

  .notice {
    @include lib-css(color, $text__color__muted);
    @include lib-font-size($font-size__s);
    margin: $indent__s 0;
  }

  .product.thumbs {
    margin: $indent__base 0 $indent__l;
  }

  .items.thumbs {
    @include lib-list-inline();

    .active {
      display: block;
      line-height: 1;
    }
  }
}

.product.info.detailed {
  clear: both;
  margin-bottom: 44px;

  .additional-attributes {
    width: auto;
    @include lib-table-resize(
      $_th-padding-left   : 0,
      $_th-padding-right  : $indent__l,
      $_th-padding-bottom : $indent__s,
      $_td-padding-bottom : $indent__s
    );
  }
}

.product-info-main {
  .page-title-wrapper {
    .page-title {
      line-height: $line-height__base;
      margin-bottom: $indent__s;
    }
  }

  .stock {
    &.available,
    &.unavailable {
      display: block;
      margin-bottom: $indent__s;
      text-transform: uppercase;
      vertical-align: top;
    }

    &.available {
      color: $success__color;
    }
  }

  .product {
    &.attribute {
      &.sku {
        display: block;
        vertical-align: top;
        @include lib-css(color, $text__color);

        > .value {
          display: inline-block;
          vertical-align: top;
          word-break: break-all;
        }

        .type {
          margin-right: $indent__xs;
        }
      }

      &.overview {
        margin: $indent__base 0;
      }
    }

    &.alert {
      margin: $indent__s 0;
    }
  }

  .price-box {
    margin-top: $indent__s;
  }

  .product-reviews-summary .reviews-actions {
    @include lib-font-size($font-size__base);
    color: $text__color;

    a {
      color: $text__color;
      text-transform: none;
    }
  }
}

.product-options-wrapper {
  .fieldset-product-options-inner {
    .legend {
      @include lib-css(font-weight, $font-weight__bold);
      @include lib-css(margin, 0 0 $indent__xs);
      @include lib-font-size(14px);
      border: none;
      display: inline-block;
      float: none;
      padding: 0;
    }

    //  Date & Time custom option (Affect Time that goes only after Date)
    input.datetime-picker {
      ~ select.datetime-picker {
        margin-top: $indent__s;
      }
    }

    &.required,
    &._required {
      .legend {
        &:after {
          content: '*';
          @include lib-typography(
            $_font-size   : $form-field-label-asterisk__font-size,
            $_color       : $form-field-label-asterisk__color,
            $_font-family : $form-field-label-asterisk__font-family,
            $_font-weight : $form-field-label-asterisk__font-weight,
            $_line-height : $form-field-label-asterisk__line-height,
            $_font-style  : $form-field-label-asterisk__font-style
          );
          @include lib-css(margin, $form-field-label-asterisk__margin);
        }
      }
    }
  }

  .field {
    .note {
      display: block;
    }

    .price-notice {
      @extend .abs-adjustment-incl-excl-tax;
    }
  }
}

.product-info-main,
.product-options-bottom {
  .price-box {
    .price-including-tax + .price-excluding-tax,
    .weee + .price-excluding-tax,
    .weee {
      line-height: 1.2;
      margin-bottom: $indent__xs;

      .price {
        font-size: 2em;
        color: $accent;
      }
    }

    .price-wrapper .price {
      font-size: 2em;
      color: $accent;
    }

    .price {
      white-space: nowrap;
    }
  }

  .special-price {
    display: block;
    margin: $indent__s 0;

    .price-container {
      @include lib-font-size(14);
    }

    .price-label + .price-wrapper {
      display: inline-block;
    }
  }

  .old-price,
  .special-price {
    .price-label {
      &:after {
        content: ': ';
      }
    }
  }

  .box-tocart {
    margin: $indent__base 0;

    .field.qty {
      padding-right: 0.75 * $indent__base;
    }

    .input-text.qty {
      $tocart-input-size: $button__line-height__l + 30px;
      height: $tocart-input-size;
      text-align: center;
      width: $tocart-input-size;
    }

    .actions {
      text-align: center;
    }

    .action.tocart {
      @extend .abs-button-l;
    }
  }

  .product-addto-links {
    margin: $indent__base 0;
  }

  .action.tocompare {
    @extend .abs-action-addto-product;
    vertical-align: top;
  }

  .product-info-offers {
    color: $success__color;
    margin: $indent__base 0;
  }
}

.prices-tier {
  @extend .abs-reset-list;
  @include lib-css(background, $sidebar__background-color);
  margin: $indent__s 0;
  padding: $indent__s (0.75 * $indent__base);

  .price-container {
    display: inline-block;
  }

  .price-including-tax,
  .price-excluding-tax,
  .weee {
    display: inline-block;

    .price {
      @include lib-font-size(14);
      font-weight: $font-weight__bold;
    }
  }
}

.ui-dialog-titlebar-close {
  @include lib-button-as-link();
}

.block.related {
  .action.select {
    margin: 0 $indent__xs;
  }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
  .product-items {
    .product-item {
      margin-bottom: $indent__base;
      position: relative;
    }

    .product-item-info {
      position: relative;
      width: auto;

      .product-item-photo {
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .product-item-name {
      margin-top: 0;
    }

    .product-item-details {
      margin: 0 0 0 85px;
    }

    .product-item-actions {
      display: block;
      margin-top: $indent__s;
    }

    .price-box {
      display: block;
      margin: 7px 0;
    }

    .text {
      margin-right: 8px;
    }

    .counter {
      @include lib-css(color, $primary__color__lighter);
      @include lib-font-size(12);
      white-space: nowrap;
    }

    .minilist {
      .price {
        display: inline;
        padding: 0;
      }

      .weee:before {
        display: inline-block;
      }
    }
  }

  .action {
    &.delete {
      @extend .abs-remove-button-for-blocks;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .subtitle {
    @extend .abs-no-display;
  }

  //
  //  Product images only
  //  ---------------------------------------------

  .product-items-images {
    @extend .abs-add-clearfix;
    margin-left: -$indent__xs;

    .product-item {
      @extend .abs-add-box-sizing;
      float: left;
      padding-left: $indent__xs;
    }
  }

  //
  //  Product names only
  //  ---------------------------------------------

  .product-items-names {
    .product-item {
      margin-bottom: $indent__s;
    }

    .product-item-name {
      margin: 0;
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .catalog-product-view {
    .column.main {
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-prefix-flex-direction(column);
    }

    .product.media {
      @include lib-vendor-prefix-order(-1);
    }
  }

  .product-info-main .box-tocart {
    .actions {
      .action.tocart {
        @extend .abs-button-responsive-smaller;
      }
    }
  }

  .block.related {
    .action.select {
      display: block;
      margin: $indent__xs 0;
    }
  }

  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none;
  }
}

@include max-screen($screen__m) {
  .product-info-main .box-tocart {
    .fieldset {
      display: flex;
      width: 100%;

      .actions {
        flex-grow: 2;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .product-image-container {
    padding: 36px;
  }

  .product-info-main,
  .product-options-bottom {
    .box-tocart {
      display: table;

      .field.qty {
        display: table-cell;
      }

      .actions {
        display: table-cell;
        text-align: center;
        vertical-align: bottom;

        .action {
          min-width: 220px;
        }
      }
    }
  }

  .product-info-main {
    margin-bottom: $indent__m;
  }

  .sidebar {
    .product-items {
      .product-item-info {
        .product-item-photo {
          float: left;
          left: auto;
          margin: 0 $indent__s $indent__s 0;
          position: relative;
          top: auto;
        }
      }

      .product-item-details {
        margin: 0;
      }

      .product-item-actions {
        clear: left;
      }
    }
  }

  .product-add-form {
    @extend .abs-revert-field-type-desktop;
  }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
  .sidebar {
    .product-items {
      .product-item-info {
        .product-item-photo {
          float: none;
          left: 0;
          margin: 0;
          position: absolute;
          top: 0;
        }
      }

      .product-item-details {
        margin-left: 85px;
      }
    }
  }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
  .product-info-main {
    float: right;
  }

  .product.media {
    float: left;
    margin-bottom: $indent__m;

    // Reduce size of image slightly
    padding: 40px;
    // max-height: 440px;
    overflow: hidden;
  }

  .page-layout-1column {
    .product-info-main {
      width: 46%;
    }
    .product.media {
      width: 46%;
    }
  }

  .page-layout-2columns-left,
  .page-layout-2columns-right,
  .page-layout-3columns {
    .product-info-main {
      width: 48%;
    }

    .product.media {
      width: 50%;
    }
  }
}

//
//  Compare Products Page
//  ---------------------------------------------

body.catalog-product-compare-index {
  .action.print {
    float: right;
    margin: 15px 0;
  }
}

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto;
}

.table-comparison {
  table-layout: fixed;

  .cell.label.remove,
  .cell.label.product {
    span {
      @extend .abs-visually-hidden;
    }
  }

  .cell.label,
  td:last-child {
    border-right: $table__border-width $table__border-style $table__border-color;
  }

  .cell {
    padding: 15px;
    width: 140px;

    .attribute.value {
      overflow: hidden;
      width: 100%;
    }

    &.product.info,
    &.product.label {
      border-bottom: $table__border-width $table__border-style $table__border-color;
    }

    &.label {
      .attribute.label {
        display: block;
        width: 100%;
        word-wrap: break-word;
      }
    }

    &.attribute {
      @include lib-font-size(13);
      img {
        height: auto;
        max-width: 100%;
      }
    }
  }

  .product-item-photo {
    display: block;
    margin: 0 auto 15px;
  }

  .product-image-photo {
    margin-left: 0;
  }

  .product-item-actions,
  .price-box,
  .product.rating,
  .product-item-name {
    display: block;
    margin: 15px 0;
  }

  .product-addto-links {
    margin-top: 15px;

    .action.split,
    .action.toggle {
      @include lib-button-s();
    }

    .action.toggle {
      padding: 0;
    }
  }

  .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right;

    .action.delete {
      @extend .abs-remove-button-for-blocks;
    }
  }

  .product-item-actions {
    > .actions-primary {
      + .actions-secondary {
        margin-top: $indent__s;
      }
    }
  }

  .action {
    &.tocart {
      white-space: nowrap;
    }
  }
}

.comparison.headings {
  @include lib-css(background, $page__background-color);
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2;
}

.block-compare {
  .block-title {
    @extend .abs-block-title;
  }

  .product-item .product-item-name {
    margin-left: 22px;
  }

  .action {
    &.delete {
      @extend .abs-remove-button-for-blocks;
      left: -6px;
      position: absolute;
      top: 0;
    }

    &.compare {
      @extend .abs-revert-secondary-color;
    }
  }

  .counter {
    @extend .abs-block-items-counter;
  }

  .actions-toolbar {
    margin: 17px 0 0;
  }
}
