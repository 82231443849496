//
//  Variables
//  _____________________________________________

$minicart__border-color       : $color-gray80 !default;
$minicart__padding-horizontal : $indent__base !default;

$minicart-qty__height         : 20px !default;

//
//  Minicart
//  ---------------------------------------------

.block-minicart {
  .items-total {
    float: left;
    text-align: left;
    margin: 0 $indent__s 22px 0;
    font-size: 0.9rem;
    padding-top: 6px; // Line up with price vertically

    .count {
      font-weight: $font-weight__bold;
    }

    a.action {
      display: block;
      color: $text__color;
    }
  }

  .subtotal {
    margin: 0 0 22px;
    text-align: right;

    .label {
      display: none;
      @extend .abs-colon;
    }
  }

  .amount {
    .price-wrapper {
      &:first-child {
        .price {
          font-size: $font-size__xl;
        }
      }
    }
  }

  .subtitle {
    display: none;
    font-weight: normal;

    &.empty {
      display: block;
      font-size: 14px;
      padding: $indent__l 0 $indent__base;
      text-align: center;
    }
  }

  .text {
    &.empty {
      text-align: center;
    }
  }

  .block-content {
    > .actions {
      margin-top: 15px;
      text-align: center;

      > .primary {
        margin: 0;

        .action {
          &.primary {
            @extend .abs-button-l;
            display: block;
            margin-bottom: 0.5rem;
            width: 100%;

            padding: 12px 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      > .secondary {
        display: block;
        width: 100%;
      }
    }
  }

  .block-category-link,
  .block-product-link,
  .block-cms-link,
  .block-banners {
    margin: 15px 0 0;
    text-align: center;
  }
}

.minicart-wrapper {
  @include lib-dropdown(
    $_toggle-selector                     : '.action.showcart',
    $_options-selector                    : '.block-minicart',
    $_dropdown-toggle-icon-content        : $icon-cart,
    $_dropdown-toggle-active-icon-content : $icon-cart,
    $_dropdown-list-item-padding          : false,
    $_dropdown-list-item-hover            : false,
    $_icon-font-position                  : before,
    $_icon-font-size                      : 32px,
    $_icon-font-line-height               : 32px
  );

  .block-minicart {
    @include lib-css(padding, 25px $minicart__padding-horizontal);
    right: 0;
    width: 320px;
    z-index: 101;

    border: none;
    border-top: 1px solid $accent;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);

    padding: 18px 22px 25px 22px;

    h3 {
      margin-top: 0;
      margin-bottom: $indent__s;
      text-align: left;
      font-size: 1.1rem;
    }

    .block-title {
      display: none;
    }

    &:after {
      left: auto;
      right: 25px;
    }

    &:before {
      left: auto;
      right: 26px;
    }
  }

  .product {
    .actions {
      float: right;
      margin: -28px 0 0;
      text-align: right;

      > .primary,
      > .secondary {
        display: inline;
      }
    }
  }

  .action {
    &.close {
      @include lib-button-icon(
          $icon-remove,
        $_icon-font-size        : 32px,
        $_icon-font-line-height : 32px,
        $_icon-font-text-hide   : true
      );
      @include lib-button-reset();
      height: 40px;
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      background: transparent;
      outline: none;
    }

    &.showcart {
      white-space: nowrap;
      position: absolute;

      &:before,
      &.active:before,
      &:hover:before {
        content: ' ';
        display: block;
        margin: 0 auto 4px auto;

        width: 30px;
        height: 23px;

        // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.21 21.9" width="26.21" height="21.9"><g data-name="Layer 2"><g data-name="Layer 1" stroke-miterlimit="10"><rect x=".5" y="4" width="25.21" height="5.28" rx="1.17" fill="#fff" stroke="#143149"/><path d="M24.11 9.29l-1 10.21a1.91 1.91 0 01-1.9 1.9h-16a1.91 1.91 0 01-1.9-1.9L2.48 9.29" fill="#fff" stroke="#143149"/><path fill="none" stroke="#143149" d="M7.66 18.64V11.6M11.62 18.64V11.6M15.03 18.64V11.6"/><path fill="none" stroke="#000" d="M19 18.64V11.6"/><path fill="none" stroke="#143149" d="M19.55 4L15.58.37M6.79 4L10.76.37"/></g></g></svg>
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.21 21.9' width='26.21' height='21.9'%3E%3Cg data-name='Layer 2'%3E%3Cg data-name='Layer 1' stroke-miterlimit='10'%3E%3Crect x='.5' y='4' width='25.21' height='5.28' rx='1.17' fill='%23fff' stroke='%23143149'/%3E%3Cpath d='M24.11 9.29l-1 10.21a1.91 1.91 0 01-1.9 1.9h-16a1.91 1.91 0 01-1.9-1.9L2.48 9.29' fill='%23fff' stroke='%23143149'/%3E%3Cpath fill='none' stroke='%23143149' d='M7.66 18.64V11.6M11.62 18.64V11.6M15.03 18.64V11.6'/%3E%3Cpath fill='none' stroke='%23000' d='M19 18.64V11.6'/%3E%3Cpath fill='none' stroke='%23143149' d='M19.55 4L15.58.37M6.79 4L10.76.37'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .text {
        @extend .abs-visually-hidden;
      }

      .counter.qty {
        @include lib-css(background, $active__color);
        @include lib-css(color, $page__background-color);
        @include lib-css(height, $minicart-qty__height);
        @include lib-css(width, $minicart-qty__height);
        @include lib-css(line-height, $minicart-qty__height);
        border-radius: 100%;
        box-sizing: border-box;
        display: inline-block;
        margin: 3px 0 0;
        overflow: hidden;
        padding: 0 1px 0 2px;
        text-align: center;
        white-space: normal;
        font-weight: bold;

        position: absolute;
        top: 4px;
        left: calc(50% + 6px);
        font-size: 10px;

        &.empty {
          display: none;
        }

        .loader {
          > img {
            @include lib-css(max-width, $minicart-qty__height);
          }
        }
      }

      .counter-label {
        @extend .abs-visually-hidden;
      }

      .price {
        color: $primary;
        font-size: 0.8rem;
      }
    }
  }

  .minicart-widgets {
    margin-top: 15px;
  }
}

.minicart-items-scroll-indicator {
  display: none;

  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;

  height: 40px;
  line-height: 40px;
  width: 100%;
  background: $primary;
  color: #FFF;

  span {
    @include lib-icon-font(
      $_icon-font-content: $icon-down,
      $_icon-font-size: 28px,
      $_icon-font-line-height: 16px,
      $_icon-font-text-hide: false,
      $_icon-font-position: after
    );
  }
}

.minicart-items-wrapper {
  // @include lib-css(border, 1px solid $minicart__border-color);
  // @include lib-css(margin, 0 (-$minicart__padding-horizontal));
  border-left: 0;
  border-right: 0;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 15px 0;
  position: relative;

  .product-image-container {
    padding: 0;
  }

  &.overflowed .minicart-items-scroll-indicator {
    display: block;
  }
}

.minicart-items {
  @include lib-list-reset-styles();

  .product-item {
    padding: $indent__base 0;

    &:nth-child(odd) {
      border-bottom: 2px solid $primary;
    }

    &:nth-child(even) {
      border-bottom: 2px solid $accent;
    }

    &:first-child {
      padding-top: 0;
    }

    > .product {
      @extend .abs-add-clearfix;
    }
  }

  .product-image-wrapper {
    @extend .abs-reset-image-wrapper;
  }

  .product-item-pricing {
    .label {
      display: inline-block;
      width: auto;
    }
  }

  .price-minicart {
    margin-bottom: $indent__xs;
  }

  .product {
    > .product-item-photo,
    > .product-image-container {
      float: left;
    }

    .toggle {
      @include lib-icon-font(
        $_icon-font-content     : $icon-down,
        $_icon-font-size        : 28px,
        $_icon-font-line-height : 16px,
        $_icon-font-text-hide   : false,
        $_icon-font-position    : after,
        $_icon-font-display     : block
      );
      cursor: pointer;
      position: relative;

      &:after {
        position: static;
        right: $indent__base;
        top: 0;
      }
    }

    &.active {
      > .toggle {
      }
    }
  }

  .product-item-name {
    font-weight: $font-weight__regular;
    margin: 0 0 $indent__xs;

    a {
      @include lib-css(color, $primary);
    }
  }

  .product-item-details {
    padding-left: 96px;
    text-align: left;

    .price {
      // font-weight: $font-weight__bold;
      color: $accent;
    }

    .price-including-tax,
    .price-excluding-tax {
      margin: $indent__xs 0 0;
    }

    .weee[data-label] {
      @include lib-font-size(11);
      .label {
        @extend .abs-no-display;
      }
    }

    .details-qty {
      margin-top: $indent__s;
    }
  }

  .product.options {
    .tooltip.toggle {
      @include lib-icon-font(
          $icon-down,
        $_icon-font-size        : 28px,
        $_icon-font-line-height : 28px,
        $_icon-font-text-hide   : true,
        $_icon-font-margin      : -3px 0 0 7px,
        $_icon-font-position    : after
      );

      .details {
        display: none;
      }
    }
  }

  .details-qty,
  .price-minicart {
    .label {
      @extend .abs-colon;
    }
  }

  .item-qty {
    margin-right: $indent__s;
    text-align: left;
    width: 46px;
    height: auto;
    padding: 4px;
  }

  .update-cart-item {
    @include lib-font-size(11);
    vertical-align: top;
  }

  .subtitle {
    display: none;
  }

  .action {
    &.edit,
    &.delete {
      @include lib-icon-font(
          $icon-settings,
        $_icon-font-size         : 28px,
        $_icon-font-line-height  : 28px,
        $_icon-font-text-hide    : true,
        $_icon-font-color        : $color-gray19,
        $_icon-font-color-hover  : $color-gray19,
        $_icon-font-color-active : $color-gray19
      );
    }

    &.delete {
      @include lib-icon-font-symbol(
        $_icon-font-content : $icon-trash
      );
    }
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__xs) {
  .minicart-wrapper .block-minicart {
    width: 290px;
  }
}

@include max-screen($screen__s) {
  .minicart-wrapper {
    margin-top: $indent__s;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .minicart-wrapper {
    margin-left: 13px;
    .block-minicart {
      right: -15px;
      width: 390px;
    }
  }
}
