@include min-screen($screen__m) {

  .product.data.items {
    text-align: center;

    > .item.title {
      float: none;
      display: inline-block;
      vertical-align: middle;

      > .switch {
        border: none;
      }

      &.active > .switch,
      &.active > .switch:focus,
      &.active > .switch:hover {
        @include lib-css(padding-bottom, $tab-control__padding-bottom);
      }
    }

    > .item.content {
      text-align: left;
    }
  }
}

