//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : false !default;


body {
  @include lib-css(background-color, $page__background-color);
}

//
//  Header
//  ---------------------------------------------

.page-header {
  @include lib-css(background-color, $header__background-color);
  margin-bottom: $indent__base;

  padding-top: 10px;
}

.header {
  &.content {
    position: relative;
  }

  .contact, .my-account {
    display: none;
  }
}

.logo {
  margin: 0 0 $indent__s 50px;
  max-width: 100%;
  display: block;
  position: relative;
  z-index: 5;

  svg {
    max-width: 100%;
  }

  .page-print & {
    float: none;
  }
}

.page-main {
  > .page-title-wrapper {
    .page-title + .action {
      margin-top: $indent__l;
    }
  }
}

.action.skip {
  &:not(:focus) {
    @extend .abs-visually-hidden;
  }

  &:focus {
    @include lib-css(background, $color-gray94);
    @include lib-css(padding, $indent__s);
    box-sizing: border-box;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 15;
  }
}

.action-skip-wrapper {
  height: 0;
  position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
  p {
    margin: 0;
  }

  &.noscript,
  &.cookie {
    @include lib-message($_message-type: global-note);
    margin: 0;
  }

  &.cookie {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 3;

    .actions {
      margin-top: $indent__s;
    }
  }

  &.demo {
    @include lib-message($_message-type: global-caution);
    margin-bottom: 0;
    text-align: center;
  }
}

//
//  Header & Footer
//  ---------------------------------------------

.page-header,
.page-footer {
  .switcher {
    margin-right: 10px;

    .options {
      @include lib-dropdown(
              $_dropdown-actions-padding            : 0,
              $_dropdown-list-item-padding          : 0,
              $_dropdown-toggle-icon-content        : $icon-down,
              $_dropdown-toggle-active-icon-content : $icon-up,
              $_icon-font-text-hide                 : true,
              $_icon-font-size                      : 22px,
              $_icon-font-line-height               : 22px,
              $_dropdown-list-min-width             : 160px
      );

      ul.dropdown {
        a {
          display: block;
          padding: 8px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    li {
      font-size: $font-size__s;
      margin: 0;
    }

    .label {
      @extend .abs-visually-hidden;
    }

    strong {
      font-weight: $font-weight__regular;
    }
  }
}

.block-search .action.search::before {
  content: ' ';
  width: 22px;
  height: 22px;
  color: $primary;

  /*
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><defs><path id="a" d="M.5382.3867h11.613V12H.5382z"/></defs><g fill="none" fill-rule="evenodd"><path d="M13.5321 2.314c3.0194 3.0194 3.0836 7.919.1927 11.0185l-.1927.1995c-3.085 3.085-8.133 3.085-11.218 0-3.085-3.085-3.085-8.133 0-11.218 3.085-3.085 8.133-3.085 11.218 0zm-1.3262 1.3258c-2.356-2.355-6.21-2.355-8.566 0-2.356 2.356-2.356 6.211 0 8.566 2.356 2.356 6.21 2.356 8.566 0l.1766-.184c2.1779-2.3677 2.119-6.0864-.1766-8.382z" fill="#143149"/><g transform="translate(11 11.1516)"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path d="M3.9732.3867l7.707 7.707c.628.628.628 1.657 0 2.285l-1.15 1.15c-.628.629-1.656.629-2.285 0l-7.707-7.707" fill="#143149" mask="url(#b)"/></g></g></svg>
  */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M.5382.3867h11.613V12H.5382z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M13.5321 2.314c3.0194 3.0194 3.0836 7.919.1927 11.0185l-.1927.1995c-3.085 3.085-8.133 3.085-11.218 0-3.085-3.085-3.085-8.133 0-11.218 3.085-3.085 8.133-3.085 11.218 0zm-1.3262 1.3258c-2.356-2.355-6.21-2.355-8.566 0-2.356 2.356-2.356 6.211 0 8.566 2.356 2.356 6.21 2.356 8.566 0l.1766-.184c2.1779-2.3677 2.119-6.0864-.1766-8.382z' fill='%23143149'/%3E%3Cg transform='translate(11 11.1516)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath d='M3.9732.3867l7.707 7.707c.628.628.628 1.657 0 2.285l-1.15 1.15c-.628.629-1.656.629-2.285 0l-7.707-7.707' fill='%23143149' mask='url(%23b)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 100% 100%;
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
  .widget.block:not(:last-child),
  .widget:not(:last-child) {
    @extend .abs-margin-for-blocks-and-widgets;
  }
}

.widget {
  clear: both;

  .block-title {
    @extend .abs-block-title;
  }
}

.page-header,
.page-footer {
  .widget.block {
    @include lib-css(margin, $indent__base 0);
  }
}

.no-display {
  @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
  td {
    padding: 0;
  }
}

$header__breakpoint: 620px;

//
//  Small Mobile
//  _____________________________________________

@include max-screen($header__breakpoint) {
  .page-header {
    padding-top: 4px;
    // margin-bottom: $indent__s;
  }

  .logo {
    margin: 0 auto;
    width: 95%;
    max-width: 312px;

    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .header.content {
    position: relative;
    display: grid;
    grid-gap: 4px;
    grid-template-rows: 70px 38px;
    grid-template-columns: 36px 1fr 36px;

    // Underlay for nav, search, basket row... bit kludgy
    &:before {
      content: ' ';
      position: absolute;
      z-index: -1;
      padding: 4px;
      top: 69px; // Use grid values
      height: 38px; // Use grid values
      left: 0;
      right: 0;

      border-top: 1px solid $accent;
      border-bottom: 1px solid $accent;

      box-shadow: 0 -1px 0 0 #EFE1BA, 0 1px 0 0 #EFE1BA;
    }

    .nav-toggle {
      margin: auto;
      grid-row: 2;
      grid-column: 1;
    }

    .block-search {
      margin: 0 $indent__s;
      grid-row: 2;
      grid-column: 2;
    }

    .minicart-wrapper {
      width: 28px;
      height: 28px;
      grid-row: 2;
      grid-column: 3;

      .action.showcart span.price {
        display: none;
      }
    }
  }

  // Hide nav underlay where not needed
  .checkout-index-index .header.content {
    grid-template-rows: 70px;

    &:before {
      content: '';
      display: none;
    }
  }
}

@include max-screen($screen__m) {
  .header.content {
    .minicart-wrapper {
      margin: auto;
    }

    .block-search {
      position: relative;

      .control {
        border-top: none;
      }

      .field.search .label {
        display: none;
      }

      .action.search {
        display: block;
        position: absolute;
        right: 8px;
        top: 2px;
      }

      .field.search .control input.input-text {
        position: static;
        left: auto;
        margin: 0;
        height: 38px;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

  html,
  body {
    height: 100%; // Stretch screen area for sticky footer
  }

  .page-wrapper {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-prefix-flex-direction(column);
    min-height: 100%; // Stretch content area for sticky footer

    > .breadcrumbs,
    > .top-container,
    > .widget {
      box-sizing: border-box;
      width: 100%;
    }

    .ie10 &,
    .ie11 & {
      height: 100%;
    }
  }

  .navigation ul {
    padding: 0 8px;
  }

  .page-header .header {
    &.panel {
      > .header.links {
        float: right;
        font-size: 0;
        @include lib-list-inline();
        margin-left: auto;

        > li {
          font-size: 14px;
          margin: 0 0 0 15px;

          &.welcome,
          a {
            line-height: 1.4;
            color: #FFF;
          }

          &.welcome {
            a {
              @include lib-css(padding-left, $indent__xs);
            }
          }
        }
      }
    }

    &.content {
      @extend .abs-add-clearfix-desktop;
      padding: 14px $indent__base 14px;
    }
  }

  .page-header {
    border: 0;
    margin-bottom: 0;
    padding-top: 0;

    .panel.wrapper {
      background: $primary;
    }

    .header.panel {
      @extend .abs-add-clearfix-desktop;
      padding-bottom: 7px;
      padding-top: 7px;
    }

    .switcher {
      display: inline-block;
    }
  }

  .page-main {
    > .page-title-wrapper {
      .page-title {
        display: inline-block;
      }

      .page-title + .action {
        float: right;
        margin-top: $indent__base;
      }
    }
  }
}

//
// EC Snaith
//
@include min-screen($header__breakpoint) {
  header.page-header .header {
    display: flex;
    align-items: center;

    .logo {
      max-width: 40%;
      margin: 0;
      flex-grow: 0;
    }

    .block-search {
      flex-grow: 2;
    }

    .block-search {
      margin-left: 36px;
      z-index: 300;
    }

    .contact, .my-account {
      padding: 0 36px;
    }

    .contact, .my-account, .minicart-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50px;
      min-width: 30px;
      text-align: center;
    }

    .minicart-wrapper {
      padding: 0 16px;
      min-width: 80px;
    }

    .contact, .my-account {
      border-right: 1px solid $primary;

      a {
        color: $primary;
        text-decoration: none;
      }
    }
    .my-account {
      a {
        font-size: 0.8rem;
      }

      svg {
        max-width: 32px;
        margin: 3px auto 7px;
        display: block;
      }
    }

    .contact {
      font-family: $heading__font-family__base;
      font-size: 1.2rem;
      color: $primary;

      .widget {
        margin: 0;
      }
    }
  }
}

@include min-screen($screen__m) {
  header.page-header .header {
    .block-search {
      .action.search {
        top: 8px;
      }
    }
  }
}

// Larger Mobile
@media only screen and (min-width: $header__breakpoint) and (max-width: ($screen__m - 1px)) {
  header.page-header {
    padding: 2px 0;
    border-bottom: 1px solid #D3AE43;
    box-shadow: 0 1px 0 0 #EFE1BA;
  }

  header.page-header .header.content {
    .contact, .my-account {
      display: none;
    }

    .logo {
      margin-left: 16px;
    }

    .block-search {
      margin: 0 0 0 $indent__base;
    }

    .minicart-wrapper {
      padding: 0 0 0 16px;
      min-width: 60px;
    }
  }
}

// Small Tablet
@media only screen and (min-width: $screen__m) and (max-width: ($screen__l - 1px)) {
  header.page-header .header.content {
    padding: 14px;
  }

  header.page-header .header {
    .my-account {
      display: none;
    }

    .contact {
      font-size: 1rem;
      padding: 0 18px;
    }

    .minicart-wrapper {
      padding: 0 16px;
      min-width: 60px;
    }

    .block-search {
      margin-left: 18px;
    }
  }
}