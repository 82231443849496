//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px !default;

.panel.header {
  .links,
  .switcher {
    display: none;
  }
}

.nav-sections {
  background: #FFF;
  font-family: $heading__font-family__base;
  text-transform: uppercase;
}

.nav-toggle {
  cursor: pointer;
  display: block;
  font-size: 0;
  // left: 16px;
  // position: absolute;
  // top: 22px;
  z-index: 100;

  width: 28px;
  height: 20px;

  transform: rotate(0deg);
  transition: .5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #666;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2), span:nth-child(3) {
    top: 8px;
  }
  span:nth-child(4) {
    top: 16px;
  }
}

.nav-open .nav-toggle {
  span:nth-child(1), span:nth-child(4) {
    top: 8px;
    width: 0;
    left: 50%;
  }
  span:nth-child(2) {
    transform: rotate(45deg);
  }
  span:nth-child(3) {
    transform: rotate(-45deg);
  }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
  .navigation {
    padding: 0;

    .parent {
      .level-top {
        position: relative;
        @include lib-icon-font(
          $_icon-font-content  : $icon-down,
          $_icon-font-size     : 42px,
          $_icon-font-position : after,
          $_icon-font-display  : block
        );

        &:after {
          position: absolute;
          right: 7px;
          top: -8px;
        }

        &.ui-state-active {
          @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-up,
            $_icon-font-position : after
          );
        }
      }
    }
  }

  .nav-sections {
    -webkit-overflow-scrolling: touch;
    @include lib-css(transition, left 0.3s, 1);
    height: 100%;
    left: -80%;
    left: calc(-1 * (100% - #{$active-nav-indent}));
    overflow: auto;
    position: fixed;
    top: 0;
    width: 80%;
    width: calc(100% - #{$active-nav-indent});

    .switcher {
      border-top: 1px solid $color-gray82;
      font-size: 1.6rem;
      font-weight: 700;
      margin: 0;
      padding: 0.8rem 3.5rem 0.8rem 2rem;

      .label {
        display: block;
        margin-bottom: $indent__xs;
      }

      &:last-child {
        border-bottom: 1px solid $color-gray82;
      }
    }

    .switcher-trigger {
      strong {
        position: relative;
        @include lib-icon-font(
          $_icon-font-content  : $icon-down,
          $_icon-font-size     : 42px,
          $_icon-font-position : after,
          $_icon-font-display  : block
        );

        &:after {
          position: absolute;
          right: -40px;
          top: -16px;
        }
      }

      &.active strong {
        @include lib-icon-font-symbol(
          $_icon-font-content  : $icon-up,
          $_icon-font-position : after
        );
      }
    }

    .switcher-dropdown {
      @include lib-list-reset-styles();
      padding: $indent__s 0;
    }

    .header.links {
      @include lib-list-reset-styles();
      border-bottom: 1px solid $color-gray82;

      li {
        margin: 0;

        &.greet.welcome {
          border-top: 1px solid $color-gray82;
          font-weight: 700;
          padding: 0.8rem $indent__base;
        }

        > a {
          border-top: 1px solid $color-gray82;
        }
      }

      a,
      a:hover {
        @include lib-css(color, $navigation-level0-item__color);
        @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
        display: block;
        font-weight: 700;
        padding: 0.8rem $indent__base;
      }

      .header.links {
        border: 0;
      }
    }
  }

  .nav-before-open {
    height: 100%;
    overflow-x: hidden;
    width: 100%;

    .page-wrapper {
      @include lib-css(transition, left 0.3s, 1);
      height: 100%;
      left: 0;
      overflow: hidden;
      position: relative;
    }

    body {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
    }
  }

  .nav-open {
    .page-wrapper {
      left: 80%;
      left: calc(100% - #{$active-nav-indent});
    }

    .nav-sections {
      $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

      @include lib-css(box-shadow, $_shadow, 1);
      left: 0;
      z-index: 99;
    }
  }

  .nav-sections-items {
    @include lib-clearfix();
    position: relative;
    z-index: 1;
  }

  .nav-sections-item-title {
    background: $navigation__background;
    border: none;
    box-sizing: border-box;
    float: left;
    height: 71px;
    padding-top: 24px;
    text-align: center;
    width: 33.33%;

    &.active {
      background: $navigation__background-active;
      border-bottom: 0;
    }

    .nav-sections-item-switch {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .nav-sections-item-content {
    @include lib-clearfix();
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    margin-top: 72px; // Add extra 1px for extra border shadow
    width: 100%;

    border-top: 1px solid $accent;
    box-shadow: 0 -1px 0 0 #EFE1BA;

    &.active {
      display: block;
    }

    padding: $indent__m 0;
  }

  @include lib-main-navigation();

  .navigation {
    li.level0 ul {
      display: none;
    }

    li.level0 ul a {
      position: relative;
    }

    .menu-expand {
      float:right;

      display:block;
      font-family: 'icons-blank-theme';
      font-size:42px;
      position:absolute;

      right: 7px;
      top: -6px;
    }

    .level0.submenu {
      background: $navigation__background-active;

      > li {
        border-bottom: 2px solid #FFF;
      }

      > li:last-child {
        border-bottom: none;
      }
    }

    .level1.submenu {
      font-family: $font-family-name__base;
      text-transform: none;
      font-weight: normal;

      background: hsla(0, 0%, 98%, 1);
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .nav-toggle {
    display: none;
  }

  .nav-sections {
    @include lib-vendor-prefix-flex-shrink(0);
    @include lib-vendor-prefix-flex-basis(auto);
    margin-bottom: 9px;

    border-top: 1px solid $accent;
    border-bottom: 1px solid $accent;

    box-shadow: 0 -1px 0 0 #EFE1BA, 0 1px 0 0 #EFE1BA;
  }

  .nav-sections-item-title {
    display: none;
  }

  .nav-sections-item-content {
    display: block !important;
  }

  .nav-sections-item-content > * {
    display: none;
  }

  .nav-sections-item-content {
    > .navigation {
      display: block;
    }
  }

  @include lib-main-navigation-desktop();

  .panel.header {
    .links,
    .switcher {
      display: inline-block;
    }
  }

  .navigation {
    font-weight: 900;

    > ul {
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      > li {
        margin: 0;
        flex-grow: 1;
        position: relative;
      }
    }

    li a {
      transition: background-color 250ms ease, color 250ms ease;
    }

    a.level-top {
      display: block;
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }

    // Submenu dropdowns not relevant in desktop view
    .menu-expand {
      display: none;
    }

    .level0.submenu {
      border-bottom: 8px solid $accent;

      font-family: $font-family-name__base;
      text-transform: none;
      font-weight: normal;

      li {
        border-bottom: 2px solid #FFF;
      }
      li:last-child {
        border-bottom: none;
      }
    }

    .level0 {
      margin: 0;

      &.active, &.has-active {
        > .level-top {
          background: rgba($primary, 0.075);

          &:hover {
            background: $navigation-desktop-level0-item__hover__background;
          }
        }
      }

      > a.ui-state-active {
        color: $accent;
        background: $navigation__background-active;
      }
    }

    .level1 {
      position: relative;

      .submenu {
        border-bottom: 8px solid $primary;
      }
    }

    .submenu .active > a {
      background: lighten($accent, 40%);
    }
  }
}


//
//  Large Tablet
//  _____________________________________________

@media only screen and (min-width: 901px) and (max-width: $screen__l) {
  .navigation .level0 > .level-top {
    font-size: 90%;
  }
}

@media only screen and (min-width: $screen__m) and (max-width: 900px) {
  .navigation > ul {
    flex-wrap: wrap;

    .level0 > .level-top {
      padding-left: 20px;
      padding-right: 20px;

      border-bottom: 1px solid hsla(0, 0%, 95%, 1);
    }
  }
}
