.about-us {
  width: 100%;

  .page-container {
    display: none;
  }

  &__text {
    padding: 11px $indent__base $indent__l $indent__base;
    max-width: 880px;
    text-align: center;
    margin: 0 auto;
  }
}

.cms-home .about-us .page-container {
  display: block;
}

.about-us__content {
  width: 100%;

  img {
    display: block;
    margin: 0 auto;
  }
}

@include max-screen($screen__m) {
  .about-us {
    margin-bottom: -25px;
  }

  .checkout-index-index .about-us {
    margin-bottom: 0;
  }
}

@include max-screen($screen__l) {
  .about-us {
    &__content {
      // Force use of img
      background: none !important;
    }
  }
}

@include min-screen($screen__l) {
  .about-us__content {
    width: 100%;
    height: 660px;
    position: relative;

    background-size: cover;
    background-position: 50% 100%;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;

      content: ' ';

      background: rgb(255,255,255);
      background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 3%, rgba(255,255,255,0) 15%);
      background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 3%, rgba(255,255,255,0) 15%);
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 3%, rgba(255,255,255,0) 15%);
    }

    img {
      display: none;
    }
  }
}